import styled from 'styled-components';
import { BorderRadius, colors, h3, mediaQueries } from '@theme/lib';

export const StyledSmallNavLinks = styled('div')<{ open: boolean }>`
  .small-nav-links {
    &__dropdown {
      ${mediaQueries.mdLogo} {
        display: inline-block;
        margin-right: 0.5rem;
        position: ${(props) => (props.theme.key === 'whipsnade' ? 'relative' : undefined)};
      }
    }
    &__items-wrapper {
      ${mediaQueries.mdLogo} {
        display: ${(props) => (props.open ? 'block' : 'none')};
        position: absolute;
        width: ${(props) => (props.theme.key === 'whipsnade' ? '100%' : undefined)};
        z-index: 101;
        overflow: hidden;
        ${(props) => BorderRadius(props.theme.config.buttonRadius)};
        border-top-left-radius: ${(props) => (props.open ? '0' : undefined)};
        border-top-right-radius: ${(props) =>
          props.theme.key === 'whipsnade' && props.open ? '0' : undefined};
        box-shadow: 2px 2px 4px -4px ${colors.black}, -2px 2px 4px -4px ${colors.black};
      }
    }
    &__title {
      margin-bottom: 1.375rem;
      display: block;
      width: 100%;
      ${h3}
      color: ${(props) => (props.theme.key === 'zsl' ? colors.parakeet : colors.tiger)};
      ${mediaQueries.mdLogo} {
        position: relative;
        font-family: ${(props) => props.theme.fonts.button};
        font-style: normal;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 130%;
        text-align: center;
        text-transform: uppercase;
        color: ${colors.black};
        text-decoration: none;
        background-color: ${colors.zooBg};
        border: 2px solid transparent;
        ${(props) => BorderRadius(props.theme.config.buttonRadius)};
        border-bottom-right-radius: ${(props) => (props.open ? '0' : undefined)};
        border-bottom-left-radius: ${(props) => (props.open ? '0' : undefined)};
        padding: 0.51rem 2rem 0.51rem 0.5rem;
        margin-bottom: 0;
        cursor: pointer;
        &:focus,
        &:hover {
          border-color: ${(props) =>
            props.theme.key === 'zsl' ? colors.seagrass : colors.honeyBee};
        }
      }
      &::after {
        ${mediaQueries.mdLogo} {
          content: '';
          width: 14px;
          height: 8px;
          display: block;
          background-image: url('/SmallNavLinks/chevron-down.svg');
          background-repeat: no-repeat;
          background-size: contain;
          order: 1;
          background-position: center;
          transform: translate(0, -50%) ${(props) => (props.open ? 'rotate(180deg)' : '')};
          position: absolute;
          top: 50%;
          right: 0.5rem;
        }
      }
    }
    &__items {
      display: block;
      position: relative;
      width: 100%;
      margin-bottom: 1rem;
      ${mediaQueries.mdLogo} {
        padding: 0.5rem;
        margin-bottom: 0;
        width: auto;
        background-color: ${colors.zooBg};
      }
      > span {
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        ${mediaQueries.mdLogo} {
          font-family: ${(props) => props.theme.fonts.button};
          font-style: normal;
          font-weight: 400;
          font-size: 1.125rem;
          line-height: 130%;
          text-align: center;
          text-transform: uppercase;
          color: ${colors.black};
        }
      }
      color: ${(props) => (props.theme.key === 'zsl' ? colors.white : colors.black)};
      ${mediaQueries.mdLogo} {
        &:focus,
        &:hover {
          text-decoration: none;
          background-color: ${colors.white};
        }
      }
      &::after {
        content: '';
        width: 33px;
        height: 24px;
        display: block;
        background-color: ${(props) => (props.theme.key === 'zsl' ? props.theme.colors.tertiary : props.theme.colors.primary)};
        mask-image: url('/NavigationItems/arrow.svg');
        mask-repeat: no-repeat;
        mask-size: contain;
        order: 1;
        mask-position: center;
        flex: 0 0 3rem;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        ${mediaQueries.mdLogo} {
          display: none;
        }
      }
    }
  }
`;
