import React, { ReactElement, useState } from 'react';
import { useTablet } from '@/lib/hooks/useTablet';
import { useTheme } from 'styled-components';
import { breakpoints } from '@/theme/lib';
import { StyledSmallNavLinks } from './styles';

type Link = {
  title: string;
  url: string;
};

export type Props = {
  title?: string;
  links: (Link | null | undefined)[];
};

export default function SmallNavLinks({ title, links }: Props): ReactElement {
  const isTablet = useTablet(Number(breakpoints.mdLogo));
  const [open, setOpen] = useState(false);
  const { key } = useTheme();


  return (
    <StyledSmallNavLinks className="small-nav-links" open={open}>
      <div className="small-nav-links__dropdown">
        {title &&
          (isTablet ? (
            <span className="small-nav-links__title">{title}</span>
          ) : (
            <button className="small-nav-links__title" onClick={() => setOpen(!open)}>
              {title}
            </button>
          ))}
        <div className="small-nav-links__items-wrapper">
          {links.map(
            (link) =>
              link && (
                <a
                  className="small-nav-links__items"
                  key={link.title}
                  href={link.url}
                  rel="noreferrer"
                  target={key === 'zsl' ? '_blank' : '_self'}
                  title={link.title}
                >
                  <span>{link.title}</span>
                </a>
              )
          )}
        </div>
      </div>
    </StyledSmallNavLinks>
  );
}
