import styled, {css} from 'styled-components';
import {colors, inputTemplate, mediaQueries} from '@theme/lib';

type StyledSearchProps = {
  type: 'nav' | 'searchPage';
  isOpen: boolean;
};

const navButtonStyle = css`
  position: absolute;
  right: 0.90625rem;
  top: 50%;
  transform: translate(0, -50%);
  ${mediaQueries.mdLogo} {
    position: relative;
    top: 0;
    right: 0.5rem;
    transform: none;
  }
  > svg path {
    fill: ${(props) => props.theme.key === 'zsl' ? undefined : colors.black};
    ${mediaQueries.mdLogo} {
      fill: ${colors.black};
    }
  }
`;

const searchPageButtonStyle = css`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 5.25rem;
  background-color: ${colors.honeyBee};
  border-radius: ${props => props.theme.config.buttonRadius};
  ${mediaQueries.lg} {
    width: 9.125rem;
  }

  svg path {
    fill: ${colors.black};
  }
`;

const searchPageInputStyle = css`
  padding: 1rem 6.5rem 1rem 1.125rem;
  font-weight: 600;
  font-size: 1.25rem;

  ${mediaQueries.lg} {
    border-radius: ${(props) => props.theme.key === 'zsl' ? undefined : '16px'};
    font-size: 1.5rem;
    padding: 1.8rem 11.75rem 1.8rem 2.625rem;
  }
`;

const hideElement = (isOpen: boolean) => css`
  visibility: ${isOpen ? undefined : 'hidden'};
  opacity: ${isOpen ? '1' : '0'};
  z-index: ${isOpen ? '1' : '-1'};
  transition: ${isOpen ? 'opacity 0.5s' : undefined};
`;

const navDesktopInputStyle = (isOpen: boolean) => css`
  ${mediaQueries.mdLogo} {
    ${hideElement(isOpen)}
    position: absolute;
    top: 0.5rem;
    left: 0;
    bottom: 0.5rem;
    background-color: ${colors.zooBg};
    padding-right: 3.25rem;
    padding-left: 2.75rem;
  }
`;


export const StyledSearch = styled.form<StyledSearchProps>`
  position: relative;
  margin-bottom: 1.4375rem;
  ${mediaQueries.mdLogo} {
    ${(props) =>
      props.type === 'nav' &&
      css`
        position: initial;
        margin-bottom: 0;
        margin-left: 0.75rem;
      `}
  }
  ${mediaQueries.xl} {
    ${(props) =>
      props.type === 'nav' &&
      css`
        margin-left: 0.625rem;
      `}
  }
  input {
    ${inputTemplate}
    padding-right: 3rem;
    background-color: ${colors.white};
    color: ${colors.black};
    box-shadow: ${(props) => props.theme.key === 'zsl' ? undefined : '0px 5px 8px rgba(237, 122, 35, 0.11)'};
    border-radius: ${(props) => props.theme.key === 'zsl' ? '5px' : '20px'};
    &::placeholder {
      color: ${colors.black};
    }
    &:focus,
    &:active {
      border-color: ${(props) => props.theme.key === 'zsl' ? colors.parakeet : colors.honeyBee};
      outline: none;
    }

    ${mediaQueries.mdLogo} {
      border-radius: ${(props) => props.theme.key === 'zsl' ? undefined : '9px'};
      box-shadow: none;
    }
    ${(props) => props.type === 'searchPage' && searchPageInputStyle}
    ${(props) => props.type === 'nav' && navDesktopInputStyle(props.isOpen)}
  }
  button {
    padding: 0;
    border: none;
    background: none;
    margin: 0;
    z-index: 1;
    line-height: 0;
    cursor: pointer;
  }
  .button-search {
    ${(props) => props.type === 'nav' && navButtonStyle}
    ${(props) => props.type === 'searchPage' && searchPageButtonStyle}
  }
  .button-close {
    ${(props) => props.type === 'nav' && hideElement(props.isOpen) }
    display: ${(props) => props.type === 'searchPage' ? 'none': undefined };
    position: absolute;
    top: 0.5rem;
    bottom: 0.5rem;
    left: 0;
    padding: 0.45rem 0.4rem;
    background-color: ${colors.black};
    color: ${colors.zooBg};
    border-radius: ${props => props.theme.config.buttonRadius};
    svg path {
      stroke: ${colors.zooBg};
    }
  }

`;
