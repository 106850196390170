import {Dispatch, ReactElement} from 'react';
import {useTablet} from '@/lib/hooks/useTablet';
import {StyledNavigationItem, StyledParentItem} from './NavigationItems.styles';
import {breakpoints} from '@theme/lib';

export type LinkProps = {
  __typename: "LinkFieldWithTitleAndChildren"|"LinkFieldWithTitle";
  url: string;
  title: string;
  target?: string|null;
  children?: Array<LinkProps>|null;
  idx?: number;
  selectedNav?: number;
  handleClick?: Dispatch<number|undefined>;
  primary?: boolean;
  ctaLabel?: string|null;
  description?: string|null;

};

export function NavigationItem(props: LinkProps): ReactElement {
  const isTablet = useTablet(Number(breakpoints.mdLogo));
  const { url, title, target, children, idx, selectedNav, handleClick, primary } = props;
  return (
    <>
      {children ?
        <StyledParentItem
          aria-expanded={selectedNav !== -1 && idx === selectedNav}
          className={`nav-item${selectedNav !== -1 && idx === selectedNav ? " active" : ""}${primary ? " primary" : ""}`}
          onClick={() => handleClick && idx !== -1 && handleClick(idx)}>
          <span>{title}</span>
        </StyledParentItem>
      :
        <StyledNavigationItem href={url} target={target||undefined} className={`nav-item${primary ? " primary" : ""}`}>
          <span>{title}</span>
        </StyledNavigationItem>
      }
    </>
  );
}

