import { Dispatch, Fragment, ReactElement } from 'react';
import { useTablet } from '@/lib/hooks/useTablet';
import { useTheme } from 'styled-components';
import {
  StyledBackButton,
  StyledBottomCtas,
  StyledNavigation,
  StyledPrimaryNavigation,
  StyledSecondaryLink,
  StyledSecondaryNavigation,
} from './Navigation.styles';
import { LinkProps, NavigationItem } from '../NavigationItems';
import SmallNavLinks from '../SmallNavLinks';
import Search from '../Search';
import StyledButton from '../Button';
import { breakpoints } from '@/theme/lib';

const zoos = {
  zsl: { title: 'ZSL', url: 'https://www.zsl.org/' },
  london: { title: 'London Zoo', url: 'https://www.londonzoo.org/' },
  whipsnade: { title: 'Whipsnade Zoo', url: 'https://www.whipsnadezoo.org/' },
};

export type NavProps = {
  open: boolean;
  searchOpen: boolean;
  setSearchOpen?: (boolean: boolean) => void;
  menuItems: LinkProps[];
  ctaOne?: LinkProps | null;
  ctaTwo?: LinkProps | null;
  secondaryNavSelected?: number;
  handleClick?: Dispatch<number | undefined>;
  handleCloseAll?: (number: number) => void;
};

function BottomCtas({
  ctaOne,
  ctaTwo,
  open,
  searchOpen,
  setSearchOpen,
  handleCloseAll,
}: NavProps): ReactElement {
  const isTablet = useTablet(Number(breakpoints.mdLogo));
  const { key } = useTheme();
  return (
    <StyledBottomCtas open={open}>
      {isTablet && <hr />}
      <div className={`nav-ctas-wrapper ${!isTablet ? 'nav-items' : ''}`}>
        {ctaOne && <StyledButton href={ctaOne.url} target={key !== 'zsl' ? '_blank' : '_self'}>{ctaOne.title}</StyledButton>}
        {(isTablet || (!isTablet && key !== 'zsl')) && ctaTwo && (
          <StyledButton href={ctaTwo.url} target={key !== 'zsl' ? '_blank' : '_self'}>{ctaTwo.title}</StyledButton>
        )}
        {!isTablet && (
          <>
            {key === 'zsl' && (
              <SmallNavLinks title="Our Zoos" links={[zoos.london, zoos.whipsnade]} />
            )}
            <Search
              type="nav"
              keywords=""
              open={searchOpen}
              setOpen={setSearchOpen}
              toggleClick={handleCloseAll}
            />
          </>
        )}
      </div>
    </StyledBottomCtas>
  );
}

export function MobileNav(props: NavProps): ReactElement {
  const isTablet = useTablet(Number(breakpoints.mdLogo));
  const { key } = useTheme();

  const items = props.menuItems.map((item: LinkProps, idx: number) => (
    <NavigationItem
      {...item}
      key={`nav-mp-item-${idx}`}
      idx={idx}
      handleClick={props.handleClick}
      primary={true}
    />
  ));

  return (
    <StyledNavigation open={props.open} searchOpen={props.searchOpen}>
      <StyledPrimaryNavigation
        open={props.open}
        secondaryNavOpen={props.secondaryNavSelected !== -1}
        isTablet={isTablet}
        searchOpen={props.searchOpen}
      >
        <div className="nav-wrapper">
          <Search type="nav" keywords="" open={props.searchOpen} setOpen={props.setSearchOpen} />
          <div className="nav-items">{items}</div>
          <hr />
          {key === 'zsl' ? (
            <>
              <SmallNavLinks title='Our Zoos' links={[zoos.london, zoos.whipsnade]} />
              <BottomCtas {...props} />
            </>
          ) : (
            <>
              <SmallNavLinks links={[props.ctaOne, props.ctaTwo]} />
              <BottomCtas
                {...props}
                ctaOne={{
                  ...zoos.zsl,
                  __typename: 'LinkFieldWithTitle',
                }}
                ctaTwo={
                  key === 'london'
                    ? {
                        ...zoos.whipsnade,
                        __typename: 'LinkFieldWithTitle',
                      }
                    : {
                        ...zoos.london,
                        __typename: 'LinkFieldWithTitle',
                      }
                }
              />
            </>
          )}
        </div>
      </StyledPrimaryNavigation>
      <div className="nav-secondary">
        {props.menuItems.map((item: LinkProps, idx: number) => {
          return (
            item.children && (
              <StyledSecondaryNavigation
                open={props.open && props.secondaryNavSelected === idx}
                key={`secondary-item-${idx}`}
              >
                <div className="nav-wrapper">
                  <StyledBackButton onClick={() => props.handleClick && props.handleClick(-1)}>
                    <span>Back</span>
                  </StyledBackButton>
                  <div className="nav-secondary-items">
                    {item.children.map((item: LinkProps, subIdx: number) => (
                      <NavigationItem
                        {...item}
                        key={`nav-ms-item-${idx}-${subIdx}`}
                        primary={false}
                      />
                    ))}
                  </div>
                  <hr />
                  <StyledSecondaryLink className="secondary-cta" href={item.url}>
                    <span>{item.ctaLabel ? item.ctaLabel : `See all ${item.title}`}</span>
                  </StyledSecondaryLink>
                  <BottomCtas {...props} />
                </div>
              </StyledSecondaryNavigation>
            )
          );
        })}
      </div>
    </StyledNavigation>
  );
}

export default function MainNav(props: NavProps): ReactElement {
  const isTablet = useTablet(Number(breakpoints.mdLogo));

  const items = props.menuItems.map((item: LinkProps, idx: number) => (
    <Fragment key={`nav-dp-item-${idx}`}>
      <NavigationItem
        {...item}
        idx={idx}
        selectedNav={props.secondaryNavSelected}
        handleClick={props.handleClick}
        primary={true}
      />
      {item.children && (
        <div className="nav-secondary">
          <StyledSecondaryNavigation
            open={props.secondaryNavSelected === idx}
            key={`secondary-item-${idx}`}
          >
            <div className="nav-wrapper">
              <div className="nav-secondary-wrapper">
                <a className="nav-secondary-title" href={item.url}>
                  {item.title}
                </a>
                {item.description && (
                  <div className="nav-secondary-description">{item.description}</div>
                )}
              </div>
              <hr />
              <div className="nav-secondary-items">
                {item.children.map((item: LinkProps, subIdx: number) => (
                  <NavigationItem {...item} key={`nav-ds-item-${idx}-${subIdx}`} primary={false} />
                ))}
              </div>
            </div>
          </StyledSecondaryNavigation>
        </div>
      )}
    </Fragment>
  ));

  return (
    <StyledNavigation open={props.open} searchOpen={props.searchOpen}>
      <StyledPrimaryNavigation
        open={props.open}
        secondaryNavOpen={props.secondaryNavSelected !== -1}
        isTablet={isTablet}
        searchOpen={props.searchOpen}
      >
        <div className="nav-wrapper">
          <div className="nav-items">{items}</div>
        </div>
        <BottomCtas {...props} />
      </StyledPrimaryNavigation>
    </StyledNavigation>
  );
}
