import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {useTablet} from '@/lib/hooks/useTablet';
import {breakpoints} from '@theme/lib';
import {StyledSearch} from './styles';
import SearchIcon from './assets/search.svg';
import CloseIcon from './assets/close.svg';

export type SearchProps = {
  // Different style for navigation and for the search page
  type: 'nav' | 'searchPage';
  keywords: string;
  open: boolean;
  setOpen?: (boolean: boolean) => void;
  toggleClick?: (number: number) => void;
};


export default function Search({ type, keywords, open, setOpen, toggleClick }: SearchProps): ReactElement {
  const isTablet = useTablet(Number(breakpoints.mdLogo));
  const [searchValue, setSearchValue] = useState(keywords || "");
  const ref = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const closeSearch = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (open && setOpen) {
      setOpen(false);
    }
  };

  const openSearch = (e: React.MouseEvent<HTMLElement>) => {
    if (!isTablet && !open && type == "nav") {
      e.preventDefault();
      if (setOpen) {
        setOpen(true);
      }
      if (inputRef.current) {
        inputRef.current.focus();
        setTimeout(() => inputRef?.current?.focus(), 100);
      }
    }
  };

  const updateSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  /**
   * Closing search when
   * click outside of search
   */
   const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      if (setOpen) {
        setOpen(false);
      }
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <StyledSearch
      ref={ref}
      action="/search"
      role="search"
      type={type}
      isOpen={open}>
      <input ref={inputRef} name="keywords" role="searchbox" type="search" placeholder="Start typing to search...." value={searchValue} onChange={updateSearchValue} />
      <button className="button-search" aria-label="search" onClick={(e) => {
          openSearch(e);
          if (toggleClick) {
            toggleClick(-1);
          }
        }}>
        <span className="visually-hidden">Search</span>
        <SearchIcon />
      </button>
      <button className="button-close" onClick={(e) => closeSearch(e)}>
        <span className="visually-hidden">Close search</span>
        <CloseIcon />
      </button>
    </StyledSearch>
  );
}
