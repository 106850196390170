import styled, {css} from 'styled-components';
import {colors, h2, mediaQueries} from '@theme/lib';

const styledItem = css`
  &.nav-item {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    margin-bottom: 2.1875rem;
    ${h2}
    ${mediaQueries.mdLogo} {
      font-family: ${(props) => props.theme.fonts.button};
      font-style: normal;
      font-weight: 400;
      font-size: 1.125rem;
      line-height: 130%;
      text-transform: uppercase;
      margin-bottom: 0;
      width: auto;
      cursor: pointer;
      padding: 0.5rem;
      span {
        font-family: ${(props) => props.theme.fonts.button};
        position: relative;
      }
      &::before {
        content: " ";
        background: ${(props) => props.theme.key === 'zsl' ? props.theme.colors.primary : colors.honeyBee };
        border-radius: ${((props) => props.theme.config.buttonRadius)};
        animation: shrinkBox .4s;
        @keyframes shrinkBox {
          from {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transform: scale(1);
          }
          to {
            transform: scale(0);
          }
        }
      }
      &:hover,
      &:focus-visible,
      &.active {
        text-decoration: none;
        span {
          color: ${(props) => props.theme.key === 'zsl' ? colors.white: colors.black};
          margin: 0;
        }
        &:not(.primary)::after {
          display: block;
        }
        &::before {
          animation: growBox .2s;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          @keyframes growBox {
            from {
              transform: scale(0);
            }
            to {
              transform: scale(1);
            }
          }
        }
      }
    }
    ${mediaQueries.lg} {
      margin-right: 0.5rem;
    }
    ${mediaQueries.lgLogo} {
      padding: 0.5rem 0.75rem;
    }
    &::after {
      content: '';
      width: 33px;
      height: 24px;
      display: block;
      background-color: ${(props) => (props.theme.key === 'zsl' ? props.theme.colors.tertiary : props.theme.colors.primary)};
      mask-image: url('/NavigationItems/arrow.svg');
      mask-repeat: no-repeat;
      mask-size: contain;
      order: 1;
      mask-position: center;
      flex: 0 0 3rem;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      ${mediaQueries.mdLogo} {
        display: none;
        position: inherit;
        transform: none;
        top: unset;
        right: unset;
      }
    }
    &:not(.primary)::after {
      display: none;
    }
  }
`;

export const StyledNavigationItem = styled('a')`
  ${styledItem}
`;

export const StyledParentItem = styled('button')`
  border: none;
  background: none;
  padding: 0;
  text-align: left;
  ${styledItem}
`;
