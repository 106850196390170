import { useTablet } from '@/lib/hooks/useTablet';
import { breakpoints } from '@theme/lib';
import Link from 'next/link';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import StyledButton from '../../Button';
import Logo from '../../Logo';
import MainNav, { MobileNav, NavProps } from '../../Navigation';
import { LinkProps } from '../../NavigationItems';
import Cross from './assets/cross.svg';
import Hamburger from './assets/hamburger.svg';
import { StyledHamburger, StyledHeader } from './styles';

export type HeaderProps = {
  navigation: NavProps;
  ctaDonate: LinkProps;
  colourTheme: string;
};

export default function Header({ navigation, ctaDonate, colourTheme = 'default' }: HeaderProps): ReactElement {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [secondaryNavSelected, setSecondaryNavSelected] = useState(-1);

  const ref = useRef<HTMLDivElement>(null);
  const isTablet = useTablet(Number(breakpoints.mdLogo));
  const { name } = useTheme();

  const handleSecondaryNavToggle = (index: number | undefined) => {
    if (index !== undefined) {
      if (index === secondaryNavSelected) {
        setSecondaryNavSelected(-1);
      } else {
        setSecondaryNavSelected(index);
      }
    }
  };

  /**
   * Closing submenu when
   * click outside of Header
   */
  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setSecondaryNavSelected(-1);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  /** Avoid flickering effect
   * of the hover animation
   * on the menu elements
   * on first load */
  useEffect(() => {
    const preload = setTimeout(function () {
      document.getElementsByTagName('header')[0].classList.remove('preload');
    }, 500);

    return () => clearTimeout(preload);
  }, []);

  return (
    <StyledHeader
      ref={ref}
      className={`preload ${isTablet ? 'mobile' : 'desktop'}`}
      open={menuOpen}
      secondaryNavOpen={secondaryNavSelected !== -1}
      isTablet={isTablet}
    >
      {!isTablet && <div className="header-gradient" />}
      <div className="header-wrapper">
        {isTablet && (
          <StyledHamburger
            aria-expanded="false"
            aria-controls="navigation"
            open={menuOpen}
            onClick={() => {
              setMenuOpen(!menuOpen);
              setSecondaryNavSelected(-1);
            }}
          >
            <span className="visually-hidden">menu</span>
            <div className="hamburger-button" aria-hidden="true">
              <Hamburger className="hamburger-symbol" />
              <Cross className="cross-symbol" />
            </div>
          </StyledHamburger>
        )}
        <Link href="/">
          <a aria-label={`${name}`} className="header-logo-wrapper">
            <Logo logoTheme={colourTheme} />
          </a>
        </Link>
        {!isTablet && (
          <MainNav
            {...navigation}
            open={menuOpen}
            secondaryNavSelected={secondaryNavSelected}
            handleClick={handleSecondaryNavToggle}
            handleCloseAll={setSecondaryNavSelected}
            searchOpen={searchOpen}
            setSearchOpen={setSearchOpen}
          />
        )}
        <StyledButton href={ctaDonate.url} className="header-button" primary={true}>
          {ctaDonate.title}
        </StyledButton>
      </div>

      {isTablet && (
        <MobileNav
          {...navigation}
          open={menuOpen}
          secondaryNavSelected={secondaryNavSelected}
          handleClick={handleSecondaryNavToggle}
          searchOpen={searchOpen}
          setSearchOpen={setSearchOpen}
        />
      )}
    </StyledHeader>
  );
}
